
    import {
        defineComponent
    } from 'vue';

    export default defineComponent({
        props: {
            loaded: Boolean,
        },
    })
